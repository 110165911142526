import {useQuery} from '@apollo/client';

import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {getCmsDealsHomePage} from '../../src/graphql/queries/sanity-queries';
import {useCustomerContext} from '../contexts/customer-context';
import {getDealsHomePage, getDealsHomePageVariables} from '../autogen/getDealsHomePage';
import IApolloQuery from '../types/apollo-query';
import {DEFAULT_STORE_ID_FOR_LOCATIONS} from '../enums/store-enums';
import {useFeatureToggle} from '../contexts/feature-toggle';
import {PERKS_PRCING} from '../enums/feature-toggle-names';

import {useStore} from './use-store';
import {useLocationId} from './use-location';

export const useHomePageDeals = () => {
    const {data: store, loading: storeLoading} = useStore();
    const {customer, isAuthenticated, loading: customerLoading} = useCustomerContext();
    const {featureEnabled, loading: featureToggleLoading} = useFeatureToggle();
    const perksPricingEnabled = featureEnabled(PERKS_PRCING);
    const storeId = Number(store?.storeId ?? 0);
    const [locationId, locationIdLoading] = useLocationId(store?.storeId ?? DEFAULT_STORE_ID_FOR_LOCATIONS);

    const {data, loading} = useQuery<getDealsHomePage, IApolloQuery<getDealsHomePageVariables>>(getCmsDealsHomePage, {
        errorPolicy: 'all',
        skip: customerLoading || storeLoading || locationIdLoading || featureToggleLoading,
        ssr: false,
        variables: {
            authType: isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            fuelSaverCardNumber: customer?.fuelSaverNumber?.toString(),
            locationIds: [locationId],
            perksPricingEnabled,
            storeId
        }
    });

    return {
        data,
        loading: customerLoading || storeLoading || loading || featureToggleLoading
    };
};
