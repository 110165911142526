import React from 'react';
import uaParser from 'ua-parser-js';

import SerializerProvider from '../../contexts/serializer/provider';
import SuperHotDealsBody from '../../components/super-hot-deals-body';

const SuperHotDeals = ({
    pageProps: {hasWebkitSupport}
}: any) => (
    <SerializerProvider hasWebkitSupport={hasWebkitSupport}>
        <SuperHotDealsBody />
    </SerializerProvider>
);

SuperHotDeals.wantsCustomerContext = () => true;
SuperHotDeals.forceStoreSelection = false;

export const getServerSideProps = ({
    req
}: any) => {
    const {os: {name, version}} = uaParser(req.headers['user-agent']);
    const hasWebkitSupport = !(name === 'iOS' && Number.parseInt(version.split('.')[0]) < 13);

    return {
        props: {
            hasWebkitSupport
        }
    };
};

export default SuperHotDeals;
