import {
    BodyContent,
    SlotBannerProvider
} from '@hy-vee/react-web-and-mobile-ui-components';
import React, {useMemo} from 'react';
import styled from 'styled-components';

import {useSerializer} from '../contexts/serializer';
import {useHomePageDeals} from '../hooks/use-home-page-deals';
import {useSlotBannersFromSanityData} from '../hooks/use-citrus-banners';

const StyledSuperHotDealsLoading = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 1;
`;

const SuperHotDealsBody = () => {
    const {serializer} = useSerializer();
    const {data: sanityData, loading: sanityDataLoading} = useHomePageDeals();
    const homepageSanityData: any = useMemo(() => !serializer || sanityDataLoading ? [] : sanityData?.CmsDealsHomePage[0].body ?? [],
        [sanityData?.CmsDealsHomePage, sanityDataLoading, serializer]);
    const {data: banners, loading: bannersLoading} = useSlotBannersFromSanityData(homepageSanityData);

    return (
        <>
            {!homepageSanityData.length &&
                <StyledSuperHotDealsLoading data-testid="super-hot-deals-loading">
                    <img
                        alt="Loading"
                        src="https://8e3463198116a37cf901-4c8f9ce7667a46d130eda30090f49466.ssl.cf2.rackcdn.com/images/ajax-loader.gif"
                    />
                </StyledSuperHotDealsLoading>}
            <SlotBannerProvider
                banners={banners}
                bannersLoading={bannersLoading}
            >
                <BodyContent
                    body={homepageSanityData}
                    serializers={serializer}
                />
            </SlotBannerProvider>
        </>
    );
};

export default SuperHotDealsBody;
