import {useQuery} from '@apollo/client';

import IApolloQuery from '../types/apollo-query';
import {TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';
import {GetLocationByBusinessCode, GetLocationByBusinessCodeVariables} from '../autogen/GetLocationByBusinessCode';
import {getLocationByBusinessCode} from '../../src/graphql/queries/location-queries';

export const useLocationId = (storeCode: string, skip = false): [string, boolean] => {
    const {data, loading} = useQuery<GetLocationByBusinessCode, IApolloQuery<GetLocationByBusinessCodeVariables>>(
        getLocationByBusinessCode,
        {
            errorPolicy: 'all',
            skip,
            ssr: false,
            variables: {
                authType: TWO_LEGGED_AUTH_TYPE,
                businessCode: storeCode
            }
        }
    );

    if (loading || !data?.locationsByBusinessCode?.length) {
        return ['', loading];
    }

    return [data.locationsByBusinessCode[0].locationId, loading];
};
